import { useState, useEffect, useCallback } from "react";
import isEmpty from "lodash/isEmpty";
import styled from "styled-components";

import TextInput from "./shared/TextInput";
import { useUser, useUserActions } from "../hooks";

export default function Login({ onClose }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const user = useUser();
  const { login } = useUserActions();

  useEffect(() => {
    if (user) {
      onClose();
    }
  }, [user, onClose]);

  const handleEmailChange = (value) => {
    setEmail(value);
  };

  const handlePasswordChange = (value) => {
    setPassword(value);
  };

  const handleLogin = useCallback(() => {
    const run = async () => {
      const success = await login(email, password);
      if (success) {
        setEmail("");
        setPassword("");
        onClose();
      }
    };
    run();
  }, [email, login, onClose, password]);

  const handleKeyDown = useCallback(
    ({ code }) => {
      if (code === "Enter" && !isEmpty(email) && !isEmpty(password)) {
        handleLogin();
      }
    },
    [handleLogin, email, password]
  );

  useEffect(() => {
    if (document) {
      document.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      if (document) {
        document.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [handleKeyDown]);

  const canRegister = !isEmpty(email) && !isEmpty(password);

  if (user) {
    return null;
  }

  return (
    <Container>
      <Input>
        <Label>Email</Label>
        <TextInput onChange={handleEmailChange} type="email" value={email} />
      </Input>
      <Input>
        <Label>Password</Label>

        <TextInput
          onChange={handlePasswordChange}
          type="password"
          value={password}
        />
      </Input>
      <RegisterButton disabled={!canRegister} onClick={handleLogin}>
        Login
      </RegisterButton>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
    margin-top: -10px;
  }
`;

const Label = styled.div`
  display: flex;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 2px;
`;

const Input = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  width: 200px;

  @media only screen and (max-width: 800px) {
    margin-top: 10px;
  }
`;

const RegisterButton = styled.button`
  margin-bottom: -22px;

  @media only screen and (max-width: 800px) {
    margin-bottom: 0px;
    margin-top: 20px;
  }
`;
