import { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Login from "./Login";
import SignUp from "./SignUp";
import { useUser, useUserActions } from "../hooks";
import { FONT_SIZE_LINK } from "../constants";
import {
  COLOR_BORDER,
  COLOR_CONTENT_FOREGROUND,
  COLOR_TEXT_HOVER,
  COLOR_TEXT_SHADOW,
} from "../colors";

const CONTENT_TYPE_LOGIN = "CONTENT_TYPE_LOGIN";
const CONTENT_TYPE_REGISTER = "CONTENT_TYPE_REGISTER";

function Header() {
  const user = useUser();
  const { logout } = useUserActions();

  const [showHiddenContainer, setShowHiddenContainer] = useState(false);
  const [hiddenContainerContent, setHiddenContainerContent] = useState(null);

  const handleCloseHiddenContainer = () => {
    setShowHiddenContainer(false);
    setHiddenContainerContent(null);
  };

  const handleShowLogin = () => {
    if (
      showHiddenContainer === true &&
      hiddenContainerContent === CONTENT_TYPE_LOGIN
    ) {
      handleCloseHiddenContainer();
    } else {
      setShowHiddenContainer(true);
      setHiddenContainerContent(CONTENT_TYPE_LOGIN);
    }
  };

  const handleShowRegister = () => {
    if (
      showHiddenContainer === true &&
      hiddenContainerContent === CONTENT_TYPE_REGISTER
    ) {
      handleCloseHiddenContainer();
    } else {
      setShowHiddenContainer(true);
      setHiddenContainerContent(CONTENT_TYPE_REGISTER);
    }
  };

  const handleLogout = async () => {
    await logout();
  };

  return (
    <Container>
      <MainHeader>
        <LogoContainer>
          <Link to="/">Souls Quests</Link>
        </LogoContainer>
        {user ? (
          <>
            <ProfileContainer>
              <Link to={`user/${user.uid}`}>Profile</Link>
            </ProfileContainer>
            <Link to="/" onClick={handleLogout}>
              Logout
            </Link>
          </>
        ) : (
          <LoginContainer>
            <LoginClicker onClick={handleShowLogin}>Login</LoginClicker>
            <LoginClicker onClick={handleShowRegister}>Register</LoginClicker>
          </LoginContainer>
        )}
      </MainHeader>
      <HiddenContainer visible={showHiddenContainer}>
        {hiddenContainerContent === CONTENT_TYPE_LOGIN ? (
          <Login onClose={handleCloseHiddenContainer} />
        ) : null}
        {hiddenContainerContent === CONTENT_TYPE_REGISTER ? (
          <div>
            <SignUp onClose={handleCloseHiddenContainer} />
          </div>
        ) : null}
      </HiddenContainer>
    </Container>
  );
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const MainHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  font-weight: bold;
  width: 95%;
  border-bottom: 1px solid ${COLOR_BORDER};
  padding-bottom: 10px;
  align-items: flex-end;
  margin-bottom: 40px;
  margin-top: 20px;
`;

const LogoContainer = styled.div`
  & > a {
    color: ${COLOR_TEXT_HOVER};
    font-size: 44px;
  }

  font-family: "OptimusPrincepsSemiBold", serif;
  text-shadow: 0px 0px 7px ${COLOR_TEXT_SHADOW};

  &:hover {
    text-shadow: 0px 0px 11px ${COLOR_TEXT_SHADOW};
  }

  transition: text-shadow ease-in-out 0.1s;
`;

const LogoutContainer = styled.div`
  cursor: pointer;
  font-size: ${FONT_SIZE_LINK};
  font-weight: bold;

  &:hover {
    color: ${COLOR_TEXT_HOVER};
  }
`;

const LoginContainer = styled.div`
  display: flex;

  & > a {
    margin-left: 25px;
    font-size: ${FONT_SIZE_LINK};
    font-weight: bold;
  }
`;

const LoginClicker = styled.div`
  cursor: pointer;
  margin-left: 25px;
  font-size: ${FONT_SIZE_LINK};
  font-weight: bold;

  &:hover {
    color: ${COLOR_TEXT_HOVER};
  }
`;

const ProfileContainer = styled(LogoutContainer)`
  margin-right: 20px;
  font-size: ${FONT_SIZE_LINK};
  font-weight: bold;
`;

const HiddenContainer = styled.div`
  display: flex;
  height: ${({ visible }) => (visible ? "100px" : "0px")};
  margin-bottom: ${({ visible }) => (visible ? "40px" : "0px")};
  transition: all 0.4s ease-in-out;
  background-color: ${COLOR_CONTENT_FOREGROUND};
  overflow: hidden;
  align-items: center;
  padding-left: 20px;
  width: 100%;
  justify-content: center;
  box-sizing: border-box;

  @media only screen and (max-width: 800px) {
    height: ${({ visible }) => (visible ? "300px" : "0px")};
  }
`;

export default Header;
