import { useState, useEffect, useCallback } from "react";
import isEmpty from "lodash/isEmpty";
import styled from "styled-components";

import TextInput from "./shared/TextInput";
import Loading from "./shared/Loading";
import { DEFAULT_USER_DATA } from "../constants";
import { useUser, useUserActions } from "../hooks";

export default function SignUp({ onClose }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const user = useUser();
  const { createUser } = useUserActions();

  useEffect(() => {
    if (user) {
      onClose();
    }
  }, [user, onClose]);

  const handleEmailChange = (value) => {
    setEmail(value);
  };

  const handlePasswordChange = (value) => {
    setPassword(value);
  };

  const handleSignUp = useCallback(() => {
    setIsSubmitting(true);
    const run = async () => {
      const success = await createUser(email, password, {
        ...DEFAULT_USER_DATA,
        email,
      });
      if (success) {
        onClose();
      }
      if (!success) {
        setIsSubmitting(false);
      }
    };
    run();
  }, [createUser, email, onClose, password]);

  const handleKeyDown = useCallback(
    ({ code }) => {
      if (code === "Enter" && !isEmpty(email) && !isEmpty(password)) {
        handleSignUp();
      }
    },
    [handleSignUp, email, password]
  );

  useEffect(() => {
    if (document) {
      document.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      if (document) {
        document.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [handleKeyDown]);

  const canRegister =
    !isEmpty(email) && !isEmpty(password) && !isEmpty(password);

  if (user) {
    return null;
  }

  if (isSubmitting) {
    return (
      <LoadingContainer>
        <Loading size={40} />
      </LoadingContainer>
    );
  }

  return (
    <Container>
      <Input>
        <Label>Email</Label>
        <TextInput onChange={handleEmailChange} type="email" value={email} />
      </Input>
      <Input>
        <Label>Password</Label>
        <TextInput
          onChange={handlePasswordChange}
          type="password"
          value={password}
        />
      </Input>
      <RegisterButton disabled={!canRegister} onClick={handleSignUp}>
        Register
      </RegisterButton>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

const Label = styled.div`
  display: flex;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 2px;
`;

const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 100px;
`;

const Input = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  width: 200px;

  @media only screen and (max-width: 800px) {
    margin-top: 10px;
  }
`;

const RegisterButton = styled.button`
  margin-bottom: -22px;
  cursor: pointer;

  @media only screen and (max-width: 800px) {
    margin-bottom: 0px;
    margin-top: 10px;
  }
`;
