import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const config = {
  apiKey: "AIzaSyAAnRpLgwKORmii0_3nqdFfJ-n4lQ83OeE",
  authDomain: "soulsquests-4621b.firebaseapp.com",
  projectId: "soulsquests-4621b",
  storageBucket: "soulsquests-4621b.appspot.com",
  messagingSenderId: "928690533307",
  appId: "1:928690533307:web:ff6b10bd17d622b71c0a9e",
  measurementId: "G-JS3PBRN78H",
};

const firebaseApp = initializeApp(config);
export const auth = getAuth(firebaseApp);
export const analytics = getAnalytics(firebaseApp);
export const firestore = getFirestore(firebaseApp);
