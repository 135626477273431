import { useState, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { auth, firestore } from "./firebase";

export const useUser = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unlisten = onAuthStateChanged(auth, (authUser) => {
      if (authUser) {
        setUser(authUser);
      } else {
        setUser(null);
      }
    });
    return () => {
      unlisten();
    };
  }, []);

  return user;
};

export const useUserActions = () => {
  const createUser = async (email, password, data) => {
    let success = true;
    try {
      const response = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const usersRef = doc(firestore, `users/${response.user.uid}`);
      await setDoc(usersRef, data);
    } catch (error) {
      console.error(error.message);
      success = false;
    }
    return success;
  };

  const login = async (email, password) => {
    let success = true;
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.log(error.message);
      success = false;
    }
    return success;
  };

  const logout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error(error.message);
    }
  };

  return { createUser, logout, login };
};

export const useUserData = () => {
  const [userData, setUserData] = useState(null);
  const user = useUser();

  useEffect(() => {
    const run = async () => {
      try {
        if (user?.uid) {
          const docRef = doc(firestore, `users/${user.uid}`);
          const snapshot = await getDoc(docRef);
          const data = snapshot.data();
          setUserData({ ...data, id: user.uid });
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("error", error);
      }
    };
    run();
  }, [user]);

  return userData;
};
