import styled, { createGlobalStyle } from "styled-components";
import { Outlet } from "react-router-dom";

import Header from "./Header";
import {
  COLOR_BACKGROUND,
  COLOR_CONTENT_BACKGROUND,
  COLOR_CONTENT_SHADOW,
  COLOR_TEXT,
} from "../colors";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${COLOR_BACKGROUND};
    color: ${COLOR_TEXT};
  }
`;

export default function Main() {
  return (
    <Background>
      <Container>
        <GlobalStyle />
        <Header />
        <Outlet />
      </Container>
    </Background>
  );
}

const Background = styled.div`
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  background-color: ${COLOR_CONTENT_BACKGROUND};
  flex-direction: column;
  width: 72%;
  min-height: 100vh;
  padding-top: 10px;
  padding-bottom: 30px;
  box-shadow: 0px 0px 1px ${COLOR_CONTENT_SHADOW};
  align-items: center;

  @media only screen and (max-width: 1200px) {
    width: 80%;
  }

  @media only screen and (max-width: 800px) {
    width: 90%;
  }

  @media only screen and (max-width: 500px) {
    width: 95%;
  }
`;
