export const COLOR_BACKGROUND = "rgb(19, 21, 20)";
export const COLOR_BORDER = "rgba(198, 210, 205, .24)";
export const COLOR_CONTENT_BACKGROUND = "rgb(24, 30, 30)";
export const COLOR_CONTENT_FOREGROUND = "rgb(43, 55, 55)";
export const COLOR_CONTENT_SHADOW = "rgb(198, 210, 205)";
export const COLOR_TEXT = "rgb(195, 164, 121)";
export const COLOR_TEXT_HOVER = "rgb(198, 210, 205)";
export const COLOR_TEXT_SHADOW = "rgb(195, 164, 121)";
export const COLOR_LOADING = "#cccccc";
export const COLOR_INPUT_BORDER = "rgb(78, 90, 90)";
export const COLOR_INPUT_SHADOW = "rgb(24, 30, 30)";
